import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// Ää Öö Üü ẞß– you need this

const resources = {
	de: {
		translation: {
			"db": "Deutsche Bank",
			"title": "Vereinbarungsportal",
			"error": "Fehler",
			"homepage": "Startseite",
			"contracts": "Vereinbarungen",
			"login.form": "please login",
			"login.username": "username",
			"login.password": "password",
			"login.button.login": "Einloggen",
			"login.button.logout": "Abmelden",
			"login.last": "Letzte Anmeldung",
			"logout.message": "Als Mitarbeiter haben Sie keine Berechtigungen um sich einzuloggen.",
			"logout.back": "Zum Login",
			"home.page": "Hello",
			"service.agreement": "Servicevereinbarung",
			"documents": "Dokumente",
			"documents.attachment": "Anlage",
			"status": "Status",
			"category": "Kategorie",
			"expirationDate": "Laufzeit",
			"expiration.label": "Gültig bis",
			"recertification.time": "Rezertifizierung notwendig",
			"download.all": "Alle herunterladen",
			"confirm.now": "Jetzt bestätigen",
			"select.option.all.categories": "Alle Kategorien",
			"contract.list.no.contracts": "In dieser Kategorie gibt es keine Vereinbarungen",
			"contract.list.no.contracts.to.approve": "Aktuell sind keine Vereinbarungen zu prüfen",
			"contract.list.no.contracts.back": "Filter zurücksetzen",
			"contract.release.state.commissioned": "Beauftragt",
			"contract.release.state.draft": "Entwurf",
			"contract.release.state.inactive": "Deaktiviert",
			"contract.release.state.inactive_deadline": "Freigegeben (nicht mehr abschließbar)",
			"contract.release.state.invalid": "Ungültig",
			"contract.release.state.released": "Freigegeben",
			"contract.release.state.to_be_released": "Freigabe ausstehend",
			"contract.contract.state.not_confirmed": "Nicht bestätigt",
			"contract.contract.state.being_reviewed": "Wird geprüft",
			"contract.contract.state.confirmed": "Bestätigt",
			"contract.contract.state.approved_until_deadline": "Gekündigt",
			"contract.contract.state.expired": "Abgelaufen",
			"contract.contract.state.revoked": "Widerrufen",
			"contract.contract.state.undefined": "Nicht bestätigt",
			"contract.category.digital": "Digitales",
			"contract.category.product": "Produkte",
			"contract.category.services": "Services",
			"contract.valid.deadline": "Gekündigt zum",
			"menu.admin.homepage": "Startseite",
			"menu.admin.contracts": "Vereinbarungen",
			"menu.admin.open.contract": "Offene Anträge",
			"no.documents.available": "Keine Unterlagen vorhanden",
			"name": "Name",
			"description": "Beschreibung",
			"infinite.interval": "unbefristet",
			"infinite.interval.contract": "Unbefristete Vereinbarung",
			"interval.in.month": "Intervall in Monaten",
			"choose.interval": "Intervall wählen",
			"choose.category": "Kategorie wählen",
			"visibility.group": "Sichtbarkeit",
			"choose.visibility.group": "Sichtbarkeit wählen",
			"mandatory.download": "Mandatorischer Download",
			"revocation.possible": "Widerruf möglich",
			"cancellation.possible": "Kündigungsfrist konfigurieren",
			"cancellation.day.of.month": "Kündigungstag",
			"cancel.contract": "Vereinbarung kündigen",
			"cancel.confirm": "Ja, kündigen",
			"cancel.contract.confirmation": "Sind Sie sicher, dass Sie die Vereinbarung innerhalb der Kündigungsfrist kündigen möchten?",
			"contract.cancelled.successfully": "Vereinbarung erfolgreich gekündigt",
			"recertification.possible": "Rezertifizierung möglich",
			"recertification.period": "Rezertifizierungszeitraum festlegen",
			"recertification.from": "Von",
			"recertification.to": "Bis",
			"release.necessary": "Freigabe notwending",
			"abort": "Abbrechen",
			"save.as.draft": "Als Entwurf speichern",
			"send.for.approval": "Zur Freigabe schicken",
			"email.notification": "E-Mail Benachrichtigung",
			"email.confirmation": "Zusätzlich an regionale Empfänger senden",
			"reminder.firstRecertificationReminder": "Erste Erinnerungsmail",
			"reminder.secondRecertificationReminder": "Zweite Erinnerungsmail",
			"reminder.expiration": "Hinweismail zum Ablauf",
			"reminder.afterExpiration": "Hinweismail nach Ablauf",
			"reminder.input": "Zeit in Wochen",
			"recipient": "Empfänger",
			"email.info.text": "E-Mail Infotext",
			"drag.or.click.info": "Bild hierher ziehen oder klicken zum auswählen",
			"preview.image.limitations.info": "Die Datei muss vom Typ png oder jpg sein und darf nicht größer als 400KB sein",
			"thumbnail": "Vorschaubild",
			"wrong.image.provided.info": "Erlaubte Bildformate: JPG, PNG. Maximale Dateigröẞe: 400KB",
			"dynamic.form": "Dynamisches Formular",
			"document.uploader.info": "Dokument hierher ziehen oder klicken zum auswählen",
			"main.document": "Hauptdokument",
			"attachment": "Anhänge",
			"checkboxes": "Checkboxen",
			"multiple.confirmation": "Mehrfachabschluss ermöglichen",
			"add.to": "Hinzufügen",
			"delete.draft": "Entwurf löschen",
			"add.contract": "Vereinbarung erstellen",
			"before": "Vor",
			"all": "Alle",
			"months": "Monate",
			"something.went.wrong": "Etwas ist schief gelaufen",
			"day": "Tage",
			"days": "Tagen",
			"contract.not.found": "Die Vereinbarung könnte nicht gefunden werden. Möglicherweise haben Sie nicht die Berechtigung diese Vereinbarung einzusehen",
			"back.to.homepage": "Zurück zur Startseite",
			"back": "Zurück",
			"recertify.now": "Jetzt bestätigen",
			"recertify.start": "Rezertifzierung starten",
			"recertify.back.alert": "Möchten Sie den Rezertifizierungsprozess abbrechen?",
			"recertify.back.header": "Rezertifizierung abbrechen",
			"reject": "Ablehnen",
			"approve": "Freigegeben",
			"yes.revoke": "Ja, widerrufen",
			"cancel.agreement.confirmation": "Sind Sie sicher, dass Sie die Vereinbarung widerrufen möchten?",
			"revoke.confirmation": "Bestätigung widerrufen",
			"checkboxes.required.warning": "Bitte bestätigen Sie alle Felder",
			"documents.download.required.warning": "Alle Dokumente müssen heruntergeladen werden",
			"commission.questionnaire": "Vereinbarung beauftragen",
			"report.standard": "Alle Vereinbarungen",
			"report.generate": "Report erstellen",
			"report.type": "Art des Report",
			"report.close": "Abbrechen",
			"report.status": "Statusreport",
			"report.status.deadline": "Statusreport",
			"report.change": "Änderungsreport",
			"report.change.period": "Änderungsreport",
			"report.choose.date": "Datum wählen",
			"report.start.date": "Startdatum",
			"report.end.date": "Enddatum",
			"report.generated.successfully": "Report wurde erfolgreich erstellt",
			"reject.contract": "Vereinbarung ablehnen",
			"reason.for.rejection": "Grund der Ablehnung",
			"user.id.label": "FB-Nummer",
			"contract.under.review": "Vereinbarung wird geprüft",
			"status.changed": "Status geändert",
			"date.of.receipt": "Eingangsdatum",
			"subscriber": "Teilnehmer",
			"deactivate": "Alle Vereinbarungen deaktivieren",
			"deactivate.deadline": "Vereinbarung nicht mehr abschließbar machen",
			"invalid": "Ungültig",
			"save.changes": "Änderungen speichern",
			"test": "Testen",
			"contract.deactivate.alert": "Hier werden alle Vereinbarungen deaktiviert. Bei hinterlegten Kündigungsfristen bleiben die Vereinbarungen bis zum Ablauf aktiv.",
			"contract.deactivate.deadline.alert": "Hier werden Vereinbarungen deaktiviert, die noch nicht bestätigt wurden.",
			"contract.deactivate.deadline.modal.header": "Vereinbarungen deaktivieren",
			"contract.deactivate.deadline.modal.message": "Sind Sie sich sicher, dass Sie die Aktion ausführen wollen? Diese wirkt sich unmittelbar auf die Vereinbarung aus.",
			"contract.deactivate.deadline.modal.confirm": "Deaktivieren",
			"contract.invalid.modal.header": "Vereinbarungen ungültig",
			"contract.invalid.modal.confirm": "Ungültig machen",
			"contract.invalid.alert": "Hier werden alle Vereinbarungen ohne Beachtung von Kündigungsfristen und erfolgten Bestätigungen direkt ungültig erklärt.",
			"should.notify.user.info": "Benutzer über Live-Gang benachrichtigen?",
			"no": "Nein",
			"yes": "Ja",
			"contract.test.unavailable.message": "Sie können die Vereinbarung nicht testen, da noch kein Formular hinterlegt wurde.",
			"name.too.long.warning": "Name ist zu lang",
			"too.many.checkboxes.warning": "Nur 20 checkboxen erlaubt",
			"too.many.emails.warning": "Nur 20 email erlaubt",
			"contract.created.successfully": "Vereinbarung erfolgreich erstellt",
			"contract.updated.successfully": "Vereinbarung erfolgreich aktualisiert",
			"contract.deleted.successfully": "Vereinbarung erfolgreich gelöscht",
			"contract.approved.successfully": "Vereinbarung erfolgreich genehmigt",
			"contract.approved.released.successfully": "Erfolgreich beantragt - wird geprüft",
			"contract.revoked.successfully": "Vereinbarung erfolgreich widerrufen",
			"approval.rejected.successfully": "Genehmigung erfolgreich abgelehnt",
			"approval.approved.successfully": "Vereinbarung erfolgreich genehmigt",
			"contract.rejected.successfully": "Vereinbarung erfolgreich abgelehnt",
			"contract.recertified.successfully": "Vereinbarung wurde rezertifiziert",
			"data.required.for.contract": "Für Ihre Vereinbarung benötigte Daten",
			"name.of.your.company": "Name ihrer firma",
			"number.of.employees": "Anzahl der angestellten",
			"further": "Weiter",
			"questionnaire.not.provided": "Fragebogen nicht vorgesehen",
			"locations.not.provided": "Bitte wählen Sie mindestens eine Option aus",
			"loading.documents": "Dokumente laden...",
			"email.not.confirmed": "Bitte die angegebene E-Mailadresse prüfen",
			"status.not.found": "Status konnte nicht abgerufen werden. Ich könnte durch die API geändert werden",
			"redirect.to.move": "Zurück zu MoVe Marketing",
			"invalid.document.name": "Ungültiger Dokumentname",
			"empty.document.name": "Der Dokumentname darf nicht leer sein",
			"contract.under.review.message": "Der Vereinbarung wird derzeit geprüft und kann momentan nicht angezeigt werden",
			"page.cannot.be.displayed": "Die Seite kann nicht angezeigt werden",
			"contract.without.questionnaire": "Der Vereinbarung ist nicht dynamisch",
			"contract.already.released": "Vereinbarung ist bereits freigegeben. Questionnaire kann nicht getestet werden",
			"contract.list.employee": "gilt für",
			"employees.were.changed": "Der Bestätigungsprozess wurde abgebrochen, da sich Ihre Mitarbeiterdaten geändert haben. Bitte starten Sie den Prozess erneut.",
			"locations.were.changed": "Der Bestätigungsprozess wurde abgebrochen, da sich Ihre Standortdaten geändert haben. Bitte starten Sie den Prozess erneut.",
			"attachment.too.large": " Der von Ihnen gewählte Anhang ist zu groß. Die maximale Größe beträgt 3 MB."
		},
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "de", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
// if you're using a language detector, do not define the lng option

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;