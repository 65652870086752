const domain = window.location.host;
export const ACCESS_TOKEN = "accessToken";

export const API_BASE_URL: string = domain.includes("postbank")
	? (process.env.REACT_APP_API_BASE_URL_POSTBANK as string)
	: (process.env.REACT_APP_API_BASE_URL as string);
export const WEB_BASE_URL: string = process.env.REACT_APP_WEB_BASE_URL as string;
export const SSO_URL: string = domain.includes("postbank")
	? (process.env.REACT_APP_SSO_URL_POSTBANK as string)
	: (process.env.REACT_APP_SSO_URL as string);
export const LOGOUT_URL: string = domain.includes("postbank")
	? (process.env.REACT_APP_LOGOUT_URL_POSTBANK as string)
	: (process.env.REACT_APP_LOGOUT_URL as string);
export const MINUTES_30: number = 1000 * 60 * 30;
export const LAST_UPDATE = "last_update";
export const MOVE_MARKETING_REDIRECT_URL: string = domain.includes("postbank")
	? (process.env.REACT_APP_MOVE_MARKETING_REDIRECT_POSTBANK as string)
	: (process.env.REACT_APP_MOVE_MARKETING_REDIRECT as string);
