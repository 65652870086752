import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Select, SelectRef } from "react-functional-select";
import "./ContractFilter.css";
import { IBasicProps } from "../IBasicProps";
import { ICategory } from "../common/model/ICategory";
import { getAllCategories } from "./contractListService";

interface IContractFilter extends IBasicProps {
	onFilterChange?: (selected: ICategory | null) => void;
	selected?: string;
}

export let resetSelection: (() => void) | null;

export const ContractFilter: FC<IContractFilter> = (props: IContractFilter) => {
	const [categories, setCategories] = useState<ICategory[]>([]);
	const [, setSelected] = useState<ICategory | null>(null);
	const onOptionChange = useCallback((opt: ICategory | null): void => {
		setSelected(opt);
		if (props.onFilterChange != null) {
			props.onFilterChange(opt);
		}
	}, []);
	const getOptionValue = useCallback((opt: ICategory): string => opt?.key, []);
	const getOptionLabel = useCallback((opt: ICategory): string => opt?.value, []);
	const selectRef = useRef<SelectRef | null>(null);

	const allCategory: ICategory = {
		id: "",
		key: "",
		value: props.t("select.option.all.categories"),
		shopUrl: "",
	};

	const setSelectedCategory = (c: ICategory): void => {
		setSelected(c);
		selectRef.current?.setValue(c);
	};

	resetSelection = () => setSelectedCategory(allCategory);

	useEffect(() => {
		getAllCategories()
			.then(r => setCategories([allCategory].concat(r as ICategory[])))
			.catch(error => console.log(error));
	}, []);

	useEffect(() => {
		for (const c of categories) {
			if (c.key === props.selected) {
				setSelectedCategory(c);
				return;
			}
		}
	}, [categories]);

	return (
		<Select
			ref={selectRef}
			initialValue={allCategory}
			options={categories}
			placeholder={props.t("select.option.all.categories")}
			onOptionChange={onOptionChange}
			getOptionLabel={getOptionLabel}
			getOptionValue={getOptionValue}
		/>
	);
};
